.chatHeaderCont {
  /* height: 8.6vh; */
  border-bottom: solid 1px #e4e4e4;
  background-color: #fff;
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  z-index: 1;
  /* position: absolute; */
  position: relative;
  width: 100%;
  cursor: pointer;
}

.admin-tag-chat {
  width: 2.25rem;
  height: 0.75rem;
  border-radius: 0.125rem;
  background: #999;

  color: #fff;

  font-feature-settings: "clig" off, "liga" off;
  /* font-family: SF Pro Text; */
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-header-bottom {
  /* margin-top: 2%; */
  width: 100%;
  background-color: #f2f2f2;
  color: #4054b2;
  font-size: 12px;
  /* height: 3.5vh; */
  padding: 0%;
  display: flex;
  align-items: center;
  border-top: solid 1px #e4e4e4;
}

.created-time-div {
  max-width: 14.666667%;
  text-align: right;
  white-space: nowrap;
}

.job-title-text {
  padding-left: 2%;
  cursor: pointer;
}

.chatHeaderCont .chatHeaderFlex {
  align-items: center;
  width: 100%;
  padding: 1.4% 1% 1.4% 2%;
}

.chatHeaderCont .chatTime {
  font-size: 12px;
  font-weight: normal;
  color: #7e7e7e;
}

.chat-header-job-Title {
  font-size: 14px;
  font-weight: 500;
  color: #3e3e3e;
}

.comp-rate-cp {
  font-size: 10px !important;
  /* object-fit: contain; */
}

.chat-card-null {
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #118936;
  color: white;
  font-size: 20px;
}

.chatHeaderCont .chat-card-image {
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 10%;
}

.nameTitle {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;
  color: #5e5e5e;
}

.chat-header-MsgType {
  color: #7e7e7e;
  font-size: 10px;
  font-weight: normal;
  background-color: #f2f2f2;
  border-radius: 2px;
  width: fit-content;
  padding: 2px;
  height: 17px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .chatHeaderCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    position: fixed;
    z-index: 1000;
    height: 6.31rem;
    padding-top: 2%;
    padding: 2% 0% 0% 0%;
  }

  .chatHeaderCont[aria-details="extra-top"] { 
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 6.3rem;
    position: fixed;
    z-index: 1000;
    height: 6.31rem;
    padding-top: 2%;
    padding: 2% 0% 0% 0%;
  }

  .chatHeaderCont-admin[aria-details="extra-top"]{
    height: 4.6rem !important;
  }

  .chat-header-bottom {
    /* margin-top: 2%; */
    width: 100%;
    background-color: #f2f2f2;
    color: #4054b2;
    font-size: 12px;
    /* height: 3.5vh; */
    padding: 0%;
    display: flex;
    align-items: center;
    padding-left: 4%;
  }

  .chatHeaderCont .chatHeaderFlex {
    /* padding: 0% 3%; */
    padding-left: 3%;
    padding-right: 1%;
  }
  
  .admin-tag-chat {
    padding-bottom: .025rem;
  }
}
